<template>
  <div class="pictures">
    <div class="flex-grid">
      <div>
            <div class="row">
              <template v-if="currentPictures.length > 0">
                <div v-for="i of currentPictures" :key="i.filename" class="col-md-4 imgitem"> 
                  <div class="">
                    <div class="imgitem-wrap">

                      <img v-if="['jpg','png','svg'].includes(i.extension)" :src="i.pathname" :alt="i.filename">
                      <img v-else :src="'../storage/custom/'+ i.extension +'.svg'"  :alt="i.filename">
                      
                      <div class="">
                        <div class="imgitem-name">{{ i.filename }}</div>
                        <a :href="i.pathname" download class="btn">
                          Download
                        </a>
                      </div>
                    </div>
                  </div> 
                </div>
              </template>
            </div>
            <div v-if="currentPictures.length == 0">
              <div class="no-image imgitem-name ">
                No Images uploaded
              </div>
            </div>
        </div>
      </div>
  </div>
</template>




<script>
import axios from 'axios';
export default {
  name: 'pictures',
  data: () => ({
    images: {},
    imgToShow: 0,
    imgToAdd: 10,
    number: 1,
    counter: 1,
  }),
  computed: {
    routePictures() {
      return this.$route.params.pictures
    },
    currentPictures() {
      if (this.images[this.routePictures]) {
        return this.images[this.routePictures]
      } else {
        return false
      }
    }
  },
  methods: {
    fetchData(category) {
      axios.get('/press/images/' + category)
        .then( res => {
          this.$set(this.images, category, res.data)
        })
        .catch(err => console.log(err))
    },
    getPath(data) {
      return data.pathname
    }
  },
  created() {
      this.fetchData(this.routePictures);
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.images.hasOwnProperty(to.params.pictures)) {
      this.fetchData(to.params.pictures);
    }
    next()
  }
}
</script>




<style lang="scss" scoped>
  .btn {
    float: right;
  }
  .pictures {
    width: 100%;
    // margin-top: 76px;
    // margin-top: 135px;
    
    img { 
      width: 100%;
      padding: 36px 26px 46px;
      max-height: 56vh;
      object-fit: contain;
      object-position: center;
      min-height: 462px;
    }

    .imgitem {
      width: 100%;
      border-right: 4px solid black;
      border-bottom: 4px solid black;
      padding: 26px;
    }

    .imgitem-name {
      // font-size: 45px;
      font-size: 36px;
      font-family: "Didact Gothic";
      text-transform: uppercase;
      font-weight: 600;
      overflow-wrap: break-word;
    }


    .imgitem-wrap {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      height: 100%;
      align-items: center;
    }
    


    .showmore {
      text-align: center;
      padding: 26px;

      .showmore-bttn {
        display: inline-block;
        font-size: 21px;
        font-weight: 600;
        font-family: "Hurme Design";
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
     
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  }

  .col-md-6 {
    // flex: 0 0 50%;
    width: 50%;
    display: flex;

    &:nth-child(even).imgitem {
          border-right: none;
        }
    }


  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;

    &:nth-child(3n).imgitem {
      border-right: 0;
    }
    
  }
  

  .no-image {
    text-align: center;
    padding-top: 26px;
  }


  @media screen and (max-width: 991px) {
    .pictures img {
      // min-height: inherit;
      padding: 10px 0;
    }

    .imgitem-name {
        font-size: 30px;
        padding: 16px 0 26px;
    }

    .col-md-4 {
      flex: 0 0 50%;
      max-width: 50%;


      &:nth-child(3n).imgitem {
        border-right: 4px solid black;
      }

      &:nth-child(even).imgitem {
        border-right: 0;
      }
      
    }

  }

  @media screen and (max-width: 767px) {
      .pictures {
          .imgitem-name {
            font-size: 26px;
          }
      }

  }

  @media screen and (max-width: 655px) {
    
    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }



    .pictures {
      img {
        min-height: auto;
      }

      .imgitem {
          border-right: none;
      }
    }


  }


</style>