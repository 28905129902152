<template>
  <div id="home">
    <nav>
      <div class="upper">
        <div class="logo">
          <a href="/">
            <img src="/storage/static_assets/logo/Bie.png" alt="Logo" >
          </a>
        </div>
        <div id="navBtn" class="menu-btn">
          <div class="menuBars" :class=" menu.active ? 'active' : '' " type="button" @click="menu.active = !menu.active">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div class="lower" style="position:relative;">
        <div class="arrow left" @click="prevpath()">
            <span v-if="previtem" ><img :src="'../storage/custom/arrow-left.png'" alt="arrow-left"></span>
        </div>
        <h1>
          {{ menu.items[page] }}
        </h1>
        <div class="arrow right" @click="nextpath()">
          <span v-if="nextitem"><img :src="'../storage/custom/arrow-right.png'" alt="arrow-right"></span>
        </div>
      </div>
    </nav>
    <div class="container wrapper">
      <transition name="slide-fade">
        <div v-if="menu.active" class="menu-overlay">
          <div class="center">
            <h1>BILDER</h1>
            <div class="menu-items">
              <template v-for="(v, i) of menu.items">
                <router-link :to="{ name: 'pictures', params: {pictures: i} }" :key="i" ><div @click="menu.active = !menu.active"> {{ v }}</div></router-link>
              </template>
              <a href="#" @click="logout()">Logout</a>
            </div>
          </div>
        </div>
      </transition>

      <div class="press-nav">
        <div>
        </div>
        <div>
          <a :href="`/press/download/${page}`" class="downloadAllBtn" download> 
            download all
          </a>
        </div>
      </div>

      <router-view></router-view>
    </div>
  </div>
</template>


<script>
export default {
  name: "PageHome",
  data: () => ({
    menu : {
      items: {
        BeeKeepers: "Bier og birøktere" ,
        Recipes: "Oppskrifter",
        LogoIcons: "Logo og ikoner",
        products: "Produkter",
      },
      array: [
        'BeeKeepers',
        'Recipes',
        'LogoIcons',
        'products',
      ],
      active: false
    },
    prevHeight: 0,
  }),
  computed: {
    page() {
      return this.$route.params.pictures
    },
    pageIndex() {
      return this.menu.array.indexOf(this.page)
    },
    nextitem() {
      return this.pageIndex < (this.menu.array.length - 1)
    },
    previtem() {
      return (this.pageIndex + (this.menu.array.length - 1)) > (this.menu.array.length - 1)
    }
  },
  methods: {
    logout() {
      this.axios.post('/owner/logout', {}).then( ({data, status}) => {
        window.location.reload();
      }).catch( ( error ) => {
        console.log(error)
      })
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    logout() {
      this.axios.post('/press/logout', {}).then( ({data, status}) => {
        this.$router.push({ name: 'login' })
      }).catch( ( error ) => {
        console.log(error)
      })
    },
    nextpath() {
      this.$router.push(this.menu.array[this.pageIndex + 1] )
    },
    prevpath() {
      this.$router.push(this.menu.array[this.pageIndex - 1] )
    }
  }
}

</script>



<style lang="scss" scoped>

@font-face {
    font-family: 'Hurme Design';
    src:  url('/fonts/hurmegeometricsans3-bold-webfont.ttf') format('truetype'),
          url('/fonts/hurmegeometricsans3-bold-webfont.woff2') format('woff2'),
          url('/fonts/hurmegeometricsans3-bold-webfont.woff') format('woff'),
          url('/fonts/HurmeGeometricSans3-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

* {
  font-family: 'Hurme Design';
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.downloadAllBtn--x{
  margin-right: 50px;
  position: absolute;
  left: 75%;
  top: 50%;
  transform: translate(0, -50%);
}

#navBtn {
  display: flex;
  grid-area: links;
  justify-content: center;
  align-items: center;
  .menuBars {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    span {
      border: 2px solid black;
      width: 35px;
      display: block;
      margin: 6px;
      border-radius: 10px;
      transition: all 0.2s ease-in;
    }
    &:hover {
      background-color: #ababab57;
    }
    &.active {
      span {
        &:nth-of-type(1) {
            transform: translate(0px, 7px) rotate(-45deg);
        }
        &:nth-of-type(2) {
            transform: translate(0px, -8px) rotate(45deg);
        }
      }
    }
  }
}

#home {
  .container {
    max-width: 1200px;
    margin: auto;
  }
  nav {
    .upper{
      display: flex;
      border-bottom: 4px solid black;
      padding: 10px 30px;
      img {
        margin : auto;
        height: 40px;
        padding: 0px;
      }
      .menu-btn {
        margin-left: auto;
        display: flex;
        align-content: center;
      }
    }
    border-bottom: 4px solid black;
    position: fixed;
    width: 100%;
    background-color: #fffef8;
    z-index: 99;
    top: 0;
    .lower {
      display: flex;
      justify-content: space-between;

      span { cursor: pointer;}
      .arrow { 
        width: 15%;
        max-width: 83px;
        min-width: 76px;
      }
      .left { 
        text-align: left;
        padding-left: 26px;
      }
      .right { 
        text-align: right; 
        padding-right: 26px;
      }
      img { 
        width: 80%; 
        padding-top: 15px;
      }
    }
  }
  .wrapper {
    position: relative;
    border-left: 4px solid black;
    border-right: 4px solid black;
    // padding-top: 67px;
    margin-top: 198px;
    min-height: 100vh;
    display: flex;
    .menu-overlay {

      max-width: 1200px;
      position: fixed;
      background: #fff;
      border-left: 4px solid black;
      border-right: 4px solid black;
      z-index: 9;

      // position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .center {
        width: 75%;
      }
      h1 {
        font-size: 100px;
        margin-top: 0px;
        margin-bottom: 20px;
      }
      .menu-items {
        display: flex;
        flex-direction: column;
        a,
        a div {
          font-size: 45px;
          font-family: 'Didact Gothic';
          text-decoration: none;
          margin: 5px 0px;
          &.router-link-active, &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .press-nav {
    position: absolute;
    // top: 143px;
    top: -55px;
    width: 100%;
    display: block;
    padding: 17px;
    background: #fffef8;
    border-bottom: 4px solid;

    display: flex;
    justify-content: space-between;
  }
}

.fade-enter-active, .fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

@media screen and (max-width: 767px) { 

  .downloadAllBtn {
      font-size: 12px;
  }

}

@media screen and (max-width: 655px) { 
  #home {

      .wrapper {
        margin-top: 190px;
      }

      .wrapper .menu-overlay {
        h1 {
          font-size: 5em;
        }
        
        .menu-items a,
        .menu-items a div {
          font-size: 28px;  
        }
      }

      nav {
         h1 {
          font-size: 21px;
        }

        .lower img{
          width: 60%;
        }

      }
  }


  
}
  
@media screen and (max-height: 883px) {
  #home .wrapper {

    .center {
      height: 100%;
      padding-top: 144px;
      padding-bottom: 26px;
    }

    .menu-overlay {
      overflow-y: scroll;

      .menu-items {
        padding-bottom: 26px;
      }
    }
  }

}

</style>

