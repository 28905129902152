import Vue from "vue";
import VueRouter from "vue-router";
import owner from "./owner";
import press from "./press";

import login from "../views/login";
import ForgotPassword from "../views/ForgotPassword";

Vue.use(VueRouter);

export default new VueRouter({
    base: "/",
    mode: "history",
    routes: [
        {
            path: "/login",
            name: "login",
            component: login
        },
        {
            path: "/ForgotPassword/:token",
            name: "ForgotPassword",
            component: ForgotPassword
        },
        {
            path: "/ForgotPassword/SmsCode/:code",
            name: "ForgotPasswordSms",
            component: ForgotPassword
        },
        ...owner,
        ...press
    ]
});
