import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
    // Add a response interceptor
    Vue.axios.interceptors.response.use(
        response => {
            if (response.status === 401) {
                router.replace("/login");
            } else {
                return response;
            }
        },
        error => {
            if (error.response.status === 401) {
                router.replace("/login");
            }
            return Promise.reject(error);
        }
    );
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);
