// Owner

import home from "../views/home/index";
import info from "../views/home/info";
import profile from "../views/home/profile";
import innmelding from "../views/home/innmelding";
import andelseier from "../views/home/andelseier";
import leveranser from "../views/home/leveranser";
import Konto from "../views/home/Konto";
import FinnKslStatus from '../views/home/FinnKslStatus';

export default [{
    path: "/owner",
    component: home,
    children: [
        {
            path: "",
            component: info,
            name: "owner"
        },
        {
            path: "profile",
            component: profile,
            name: "profile"
        },
        {
            path: "Innmelding",
            component: innmelding,
            name: "Innmelding"
        },
        {
            path: "Medlem",
            component: andelseier,
            name: "Andelseier"
        },
        {
            path: "Leveranser",
            component: leveranser,
            name: "Leveranser"
        },
        {
            path: "Konto",
            component: Konto,
            name: "Konto"
        },
        {
            path: "KSLstatus",
            component: FinnKslStatus,
            name: "KSLstatus"
        }
    ]
}];
