<template>
  <div id="home">
    <header>
      <img src="/storage/static_assets/logo/Logo.png" alt="Logo">
    </header>
    <main>
      <div class="nav-wrap">
        <nav v-if="profile.Gr">
          <router-link v-if="profile.Gr != 5" :to="{ name: 'owner' }">Nyheter</router-link>
          <router-link v-if="profile.Gr != 5" :to="{ name: 'profile' }">Min profil</router-link>
          <router-link v-if="profile.Gr != 5" :to="{ name: 'Innmelding' }">Innmelding</router-link>
          <router-link v-if="profile.Gr != 5" :to="{ name: 'Andelseier' }">Medlem</router-link>
          <router-link v-if="profile.Gr != 5" :to="{ name: 'Leveranser' }">Leveranser</router-link>
          <router-link v-if="profile.Gr != 5" :to="{ name: 'Konto' }">Min konto</router-link>
          <router-link v-if="profile.Gr == 5" :to="{ name: 'KSLstatus' }">Finn KSL status</router-link>
          <a @click="logout()">
            {{ loggingOut ? 'Laster...' : 'Logg ut'}}
            
          </a>
        </nav>
      </div>
      <section>
        <transition
          name="fade"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
        >
          <router-view></router-view>
        </transition>
      </section>
    </main>
    <footer>
      <div v-for="(i, f) in footer" :key="`footer-${f}`">
        <span :key="f">{{ i.header }}</span>
        <ul>
          <li v-for=" (x, t) in i.items " :key="`footerItems-${t}`">
            <a :href="x.link" v-text="x.title" :key="t" target="_blank"></a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

// TODO when logout button is click show loading animation icon
// TODO when error is CSRF token mismatch -> reload the page


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PageHome",
  data: () => ({
    footer : [
      {
        header: "Nyttig for birøktere",
        items: [
          {title: 'Aktuelt', link: '/Aktuelt'},
          {title: 'KSL birøkt', link: '/ksl-birokt'},
          {title: 'Voks til birøkt', link: '/voks-til-birokt'},
          // {title: 'Nybegynnerpakke', link: '/Nybegynnerpakke'},
          {title: 'Salgs- og leveringsbetingelser', link: '/salgs-og-leveringsbetingelser'},
          {title: 'Kontakt oss', link: '/kontakt'},
        ]
      },
    ],
    prevHeight: 0,
    loggingOut: false,
    toggle: false,
    selected: false,
  }),
  created() {
    if (!this.profile.id) {
      this.getProfile();
    }

    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions(["getProfile"]),
    logout() {
      this.loggingOut = true
      this.axios.post('/owner/logout', {}).then( ({data, status}) => {
        location.reload();
      }).catch( ( error ) => {
        console.log(error)
      })
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    menu() {
      this.toggle = !this.toggle;
      this.selected = !this.selected;
    },
    handleResize() {
      if( window.innerWidth >= 883){
        this.toggle = true;
        this.selected = false;
      }else{
        this.toggle = false;
        this.selected = false;
      }
    },
  },
  computed: {
    ...mapState(["profile"]),
    logginOutStyle(){
      if (this.loggingOut) {
        return {
          backgroundColor: '#fbba00',
        }
      }else {
        return {}
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if( window.innerWidth <= 883){
      this.toggle = false;
      this.selected = false;
    }
    next()
  }
}

</script>



<style lang="scss" scoped>
#home {
  transition: all 0.3s ease;

  header { 
    display: flex;
    justify-items: center;
    img {
      width: auto;
      height: 100%;
      margin : auto;
    }
  }

  #nav-hamburger {
    display: none;
  }

  main { 
    margin-bottom: 100px;
    .nav-wrap {
      grid-area: nav;
    }
    nav {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 2px solid black;
      a {
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        padding: 10px;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        &:hover, &.router-link-exact-active {
          color: white;
          background-color: black;
        }

      }
    }
    section {
      padding: 10px;
    }
  }
  footer {     
    padding: 10px 0;
    div {
      span {
        padding: 5px 10px;
        display: block;
        border-bottom: 2px solid black;
        font-weight: bold;
        text-transform: uppercase;
      }
      ul {
        list-style-type: none;
        padding: 0;
        li {
          margin: 3px 0px;
          transition: all 0.3s cubic-bezier(.25,.8,.25,1);
          a {
            display: block;
            padding: 5px 10px;
            text-decoration: none;
            &:hover {
              color: white;
              background-color: black;
            }
          }
        }

      }
    }
  }
}


//If Grid Not Supported
#home {    
  width: 100%;
  max-width: 984px;
  margin: auto;

  header {
    width: 172px;
    margin: auto;

    img {
      width: 80%;
    }
  }
  
  footer {

    div {
      width: 232px;
    }
  }
}


@supports (display: grid) {
  #home {
    all: unset;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 200px 2fr 2fr auto; 

    header { 
      grid-area: 1 / 1 / 2 / 6;
    }

    main { 
      grid-area: 2 / 2 / 4 / 5;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 85px 3fr 3fr 3fr;
      grid-template-areas: 
        'nav nav nav nav'
        'section section section section'
        'section section section section'
        'section section section section';
      nav {
        grid-area: nav;
      }
      section {
        grid-area: section;
      }
    }
    footer { 
      grid-area: 4 / 2 / 6 / 5;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr; 
      grid-column-gap: 20px;
    }
  }
}

// Change css when the browser window is 1380px wide or less:
@media only screen and (max-width: 1380px) {

  #home {
    max-width: 1050px;
  }

  @supports (display: grid) {
    #home {
      max-width: unset;
      //===============
      grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    }
  }

}

// Change css when the browser window is 1104px wide or less:
@media only screen and (max-width: 1104px) {
  
  #home {
    max-width: 910px;
  }

  @supports (display: grid) {
    #home {
      max-width: unset;
      //===============
      grid-template-columns: 1fr 3fr 3fr 3fr 1fr;
    }
  }

}

// Change css when the browser window is 1012px wide or less:
@media only screen and (max-width: 1012px) {

  #home {
    padding: 0 6%;
    header {
      width: auto;
      margin: 0;
      position: relative;
      img {
        width: 15%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @supports (display: grid) {
    #home {
      padding: unset;
      //===============
      grid-template-columns: 1fr 4fr 4fr 4fr 1fr;
    }
  }

}

@media only screen and (max-width: 883px) {
  #home {
    header {
      width: auto;
      margin: 0;
      position: relative;
      img {
        width: 90px;
        height: auto;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    main {
      grid-template-areas:
        "div div div div"
        "nav nav nav nav"
        "section section section section"
        "section section section section"
        "section section section section";

      nav {
        display: flex;
        width: 100%;
        border: none;
      }
    }
    
    .nav-wrap {
      border-bottom: 1px solid #E9BB05;
    }

    #nav-hamburger {
      display: block;
      width: 30px;
      height: 40px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
      right: calc(-100vw + 19vw);

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #191615;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:first-child {
          top: 15px;
        }

        &:nth-child(2) {
          top: 25px;
        }
      }

      &.open {
        span {

          &:first-child {
            top: 23px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            top: 23px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }


  @supports (display: grid) {
    #home {
      grid-template-rows: 100px;
      #nav-hamburger {
          right: calc(-100% + 30px );
      }
      main {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        nav {
          display: inline-grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr;
          font-size: 13px;
          text-align: center;
          align-items: center;
          a {
            padding: 10px 0px 10px 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #home {
    grid-template-rows: 80px;
    header {
      width: auto;
      margin: 0;
      position: relative;
      img {
        width: 80px;
        height: auto;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>

