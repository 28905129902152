<template>
  <div id="andelseier">
    <h1>Medlem</h1>

    <p>Her finner du ditt andelsinnskudd, saldo på din frivillige sparing og saldo på din medlemskapitalkonto.</p>
    
    <div class="split">
      <h2>Andelsinnskudd</h2>

      <div class="table-wrap">
        <table>
          <thead>
            <tr>
              <!-- VoDT -->
              <th>Dato</th>
              <!-- txt -->
              <th>Tekst</th> 
              <!-- VoNo -->
              <th>Bilagsnr</th>
              <!-- am - negative numbers -->
              <th>Inn</th>
              <!-- am -positive numbers -->
              <th>Ut</th>
              <!-- unknown -->
              <th>Se Bilag</th>
            </tr>
          </thead>
          <tbody>
            <!-- Data Rows -->
            <template v-if="Shareholders.length > 0">
              <template v-for=" (s,i) in Shareholders ">
                <tr v-if="s.ClPrc === 3" :key="`Andelsinnskudd-${i}`" class="data">
                  <td :key="`${i}-VoDt`">{{s.VoDt}}</td>
                  <td :key="`${i}-txt`">{{s.txt}}</td>
                  <td :key="`${i}-VoNo`">{{s.VoNo}}</td>
                  <td :key="`${i}-Negative`">{{ s.am < 0 ? Math.abs(s.am) : "" }}</td>
                  <td :key="`${i}-Positive`">{{ s.am > 0 ? Math.abs(s.am) : "" }}</td>
                  <td :key="`${i}-unknown`"></td>
                </tr>
              </template>
            </template>
            <tr v-else-if="!loading" class="loading">
              <td colspan="6"> No Data </td>
            </tr>
            <tr v-else class="loading">
              <td colspan="6"> LOADING... </td>
            </tr>
          </tbody>
          <!-- Dont remove this last row -->
          <tfoot>
            <tr class="LastRow">
              <td></td>
              <td>SUM</td>
              <td></td>
              <td class="number">{{ Sum['Andelsinnskudd'].negative }}</td>
              <td class="number">{{ Sum['Andelsinnskudd'].positive }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- <h3 class="salary">SALDO: {{ Math.abs(Sum.negative - Sum.positive) }} NOK</h3> -->
      <h3 class="salary">SALDO: {{ Math.round((Sum['Andelsinnskudd'].negative - Sum['Andelsinnskudd'].positive) * 100) / 100 }} NOK</h3>
    </div>

    <div class="split">
      <h2>Frivillig sparing</h2>

      <div class="table-wrap">
        <table>
          <thead>
            <tr>
              <!-- VoDT -->
              <th>Dato</th>
              <!-- txt -->
              <th>Tekst</th> 
              <!-- VoNo -->
              <th>Bilagsnr</th>
              <!-- am - negative numbers -->
              <th>Inn</th>
              <!-- am -positive numbers -->
              <th>Ut</th>
              <!-- unknown -->
              <th>Se Bilag</th>
            </tr>
          </thead>
          <tbody>
            <!-- Data Rows -->
            <template v-if="Shareholders.length > 0">
              <template v-for=" (s,i) in Shareholders">
                <tr v-if="s.ClPrc === 2" :key="`frivilligSparing-${i}`" class="data">
                  <td :key="`${i}-VoDt`">{{s.VoDt}}</td>
                  <td :key="`${i}-txt`">{{s.txt}}</td>
                  <td :key="`${i}-VoNo`">{{s.VoNo}}</td>
                  <td :key="`${i}-Negative`">{{ s.am < 0 ? Math.abs(s.am) : "" }}</td>
                  <td :key="`${i}-Positive`">{{ s.am > 0 ? Math.abs(s.am) : "" }}</td>
                  <td :key="`${i}-unknown`"></td>
                </tr>
              </template>
            </template>
            <tr v-else-if="!loading" class="loading">
              <td colspan="6"> No Data </td>
            </tr>
            <tr v-else class="loading">
              <td colspan="6"> LOADING... </td>
            </tr>
          </tbody>
          <!-- Dont remove this last row -->
          <tfoot>
            <tr class="LastRow">
              <td></td>
              <td>SUM</td>
              <td></td>
              <td class="number">{{ Sum['frivilligSparing'].negative }}</td>
              <td class="number">{{ Sum['frivilligSparing'].positive }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- <h3 class="salary">SALDO: {{ Math.abs(Sum['frivilligSparing'].negative - Sum['frivilligSparing'].positive) }} NOK</h3> -->
      <h3 class="salary">SALDO: {{ Math.round((Sum['frivilligSparing'].negative - Sum['frivilligSparing'].positive) * 100) / 100 }} NOK</h3>
    </div>

    <div class="split">
      <h2>Medlemskapitalkonto</h2>

      <div class="table-wrap">
        <table>
          <thead>
            <tr>
              <!-- VoDT -->
              <th>Dato</th>
              <!-- txt -->
              <th>Tekst</th> 
              <!-- VoNo -->
              <th>Bilagsnr</th>
              <!-- am - negative numbers -->
              <th>Inn</th>
              <!-- am -positive numbers -->
              <th>Ut</th>
              <!-- unknown -->
              <th>Se Bilag</th>
            </tr>
          </thead>
          <tbody>
            <!-- Data Rows -->
            <template v-if="Shareholders.length > 0">
              <template v-for="(s,i) in Shareholders">
                <tr v-if="s.ClPrc === 4" :key="`Medlemskapitalkonto-${i}`" class="data">
                  <td :key="`${i}-VoDt`">{{s.VoDt}}</td>
                  <td :key="`${i}-txt`">{{s.txt}}</td>
                  <td :key="`${i}-VoNo`">{{s.VoNo}}</td>
                  <td :key="`${i}-Negative`">{{ s.am < 0 ? Math.abs(s.am) : "" }}</td>
                  <td :key="`${i}-Positive`">{{ s.am > 0 ? Math.abs(s.am) : "" }}</td>
                  <td :key="`${i}-unknown`"></td>
                </tr>
              </template>
            </template>
            <tr v-else-if="!loading" class="loading">
              <td colspan="6"> No Data </td>
            </tr>
            <tr v-else class="loading">
              <td colspan="6"> LOADING... </td>
            </tr>
          </tbody>
          <!-- Dont remove this last row -->
          <tfoot>
            <tr class="LastRow">
              <td></td>
              <td>SUM</td>
              <td></td>
              <td class="number">{{ Sum['Medlemskapitalkonto'].negative }}</td>
              <td class="number">{{ Sum['Medlemskapitalkonto'].positive }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- <h3 class="salary">SALDO: {{ Math.abs(Sum['Medlemskapitalkonto'].negative - Sum['Medlemskapitalkonto'].positive) }} NOK</h3> -->
      <h3 class="salary">SALDO: {{ Math.round((Sum['Medlemskapitalkonto'].negative - Sum['Medlemskapitalkonto'].positive) * 100) / 100 }} NOK</h3>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "andelseier",
  data: () => ({
    loading: false,
  }),
  created() {
    if( this.Shareholders.length == 0 ){
      this.loading = true;
      this.getShareholder().then(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    ...mapState(['Shareholders']),
    Sum () {
      let results = {
        Andelsinnskudd: {
          negative: 0,
          positive: 0,  
        },
        frivilligSparing: {
          negative: 0,
          positive: 0,  
        },
        Medlemskapitalkonto: {
          negative: 0,
          positive: 0,  
        },
        negative: 0,
        positive: 0,
      };
      for (const x of this.Shareholders) {
        let t = false
        if (x.ClPrc === 3) {
          t = 'Andelsinnskudd'
        } else if (x.ClPrc === 2) {
          t = 'frivilligSparing'
        } else if (x.ClPrc === 4) {
          t = 'Medlemskapitalkonto'
        }
        if (t) {
          let am = Math.abs(x.am)
          if( x.am < 0 ){3
            // nagative
            results[t].negative += am
          }else{
            // positive
            results[t].positive += am
          }
        }
      }
      return results
    },
  },
  methods: {
    ...mapActions(['getShareholder'])
  }
}
</script>



<style lang="scss" scoped>
#andelseier {
  table {
    width: 100%;
    thead {
      tr {
        th {
          padding: 5px 5px;
          text-align: start;
          color: white;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          background-color: black;
        }
      }
    }
    tbody {
      box-shadow: 0px 2px black;
      tr.data {
        td {
          padding: 1px 5px;
        }
      }
      tr.loading {
        text-align: center;
        height: 100px;
      }
    }
    tfoot {
      tr.LastRow {
        height: 30px;
        vertical-align: bottom;
        td.number {
          text-align: right;
        }
      }
    }
  }
  .salary {
    display: block;
    text-align: right
  }
}

.table-wrap {
    overflow: auto;
}
</style>
