<template>
  <div id="leveranser">
    <h1> Innmeldt {{ year }} </h1>
    <p>
      Her kommer fortløpende informasjon om innveid honning etter hvert som den er innveid og vannprøver er analysert. <br>
    </p>
    <div class="table-wrap">
    <table id="innmeldinger">
      <thead>
        <tr>
          <th>INNMELDING AV TYPE HONNING/VOKS</th>
          <th>Kg</th>
          <th>Uke</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="Innmeldinger.length > 0">
          <tr v-for="(l,i) of Innmeldinger" :key="i">
            <td>{{ l.Beskrivelse }}</td>
            <td>{{ Math.abs(l.Kg).toFixed(2) }}</td>
            <td>{{ l.Uke }}</td>
          </tr>
        </template>
        <tr v-else-if="!loading" class="loading">
          <td colspan="6"> No Data </td>
        </tr>
        <tr v-else class="loading">
          <td colspan="6"> Loading... </td>
        </tr>
      </tbody>
    </table>
    </div>

    <h1>Tidligere leveranser</h1>
    <div class="table-wrap">
    <table id="leveranser">
      <thead>
        <tr>
          <th>Leveranse ID</th>
          <th>Varetekst</th>
          <th>Levert</th>
          <th>Lev.Dato</th>
          <th>Avlest Brix</th>
          <th>Status Siling</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="Leveranser.length > 0">
          <tr v-for="(l,i) of Leveranser" :key="i">
            <td>{{ l.Bestnr }}</td>
            <td>{{ l.Varetekst }}</td>
            <td>{{ Math.abs(l.Levert).toFixed(2) }} kg</td>
            <td>{{ formatDate(l.Bestilt) }}</td>
            <!-- <td>{{ Math.abs(l.Vannprosent) }}</td> -->
            <td>{{ Math.abs(l.Free2).toFixed(1) }}</td>
            <td>{{ l.transgr3 }}</td>
          </tr>
        </template>
        <tr v-else-if="!loading" class="loading">
          <td colspan="6"> No Data </td>
        </tr>
        <tr v-else class="loading">
          <td colspan="6"> Loading... </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment';

export default {
  name: "leveranser",
  data: () => ({
    loading: false,
  }),
  created(){
    if(this.Innmeldinger.length == 0) {
      this.loading = true
      this.getInnmeldinger().then(() => {
        this.loading = false
      });
    }
    if(this.Leveranser.length == 0) {
      this.loading = true
      this.getLeveranser().then(() => {
        this.loading = false
      });
    }
  },
  computed: {
    ...mapState(['Innmeldinger','Leveranser']),
    year(){
      return new Date().getFullYear()
    },
  },
  methods: {
    ...mapActions(['getInnmeldinger', 'getLeveranser']),
    formatDate(date){
      let y = date.toString().substring(0, 4);
      let m = date.toString().substring(4, 6);
      let d = date.toString().substring(6, 8);
      let week = moment(`${m}.${d}.${y}`, "MM-DD-YYYY").week();
      return `${d}.${m}.${y} - ( Uke ${week} )`
    },
  }
}
</script>

<style lang="scss" scoped>

#leveranser {
  table {
    width: 100%;
    thead {
      tr {
        th {
          padding: 5px 5px;
          text-align: start;
          color: white;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          background-color: black;
        }
      }
    }
    tbody {
      box-shadow: 0px 2px black;
      tr.data {
        td {
          padding: 2px 5px;
        }
      }
      tr.loading {
        td {
          padding: 10px 5px;
          text-align: center;
        }
      }
    }
  }
}


.table-wrap {
    overflow: auto;
}
</style>
