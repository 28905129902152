// Owner
import press from "../views/press/index";
import pictures from "../views/press/pictures";

export default [
    {
        path: "/press",
        component: press,
        children: [
            {
                path: "",
                redirect: { name: "pictures", params: {pictures: "BeeKeepers"} },
                name: "press"
            },
            {
                path: ":pictures",
                component: pictures,
                name: "pictures"
            },
        ]
    }
];
