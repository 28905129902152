<template>
  <div id="FinnKslStatus">
    <h1>Finn KSL Status</h1>

    <!-- <p>Her ser du din kreditnotaer og fakturaer fra HC, samt dine innbetalinger og våre utbetalinger.</p> -->
    <label for="order">Antall medlemmer som skal vises</label>
    <br>
    <select name="order" id="select-order" v-model="perPage">
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="200">200</option>
      <option :value="pagination.total">Alle ({{pagination.total}})</option>
    </select>

    <div class="Search" style="margin-top: 25px;">
      <label for="order">Hvilken kolonne du vil søke</label>
      <div>
        <select name="SearchColumn" id="select-SearchColumn" v-model="SearchColumn">
          <option value="CustNo">Medlemsnummer</option>
          <option value="Nm">Navn</option>
        </select>
        <input name="Search" type="text" v-model="search" :placeholder="SearchPlaceholder" style="width: 35%; margin-left: 20px;">
      </div>
    </div>

    <h1 v-if="loading"> Laster... </h1>

    <table v-if="hasMembers">
      <thead>
        <tr>
          <th>
            <div>
              <span> Medlemsnummer </span>
              <div class="sorter">
                <span class="sort-icon" :class="OrderColumn === 'CustNo' && OrderDir === 'ASC' ? 'active' : ''" @click="setOrder('CustNo', 'ASC')"> &#9650; </span>
                <span class="sort-icon" :class="OrderColumn === 'CustNo' && OrderDir === 'DESC' ? 'active' : ''" @click="setOrder('CustNo', 'DESC')"> &#9660; </span>
              </div>
            </div>
          </th>
          <th>
            <div>
              <span> Navn </span>
              <div class="sorter">
                <span class="sort-icon" :class="OrderColumn === 'Nm' && OrderDir === 'ASC' ? 'active' : ''" @click="setOrder('Nm', 'ASC')"> &#9650; </span>
                <span class="sort-icon" :class="OrderColumn === 'Nm' && OrderDir === 'DESC' ? 'active' : ''" @click="setOrder('Nm', 'DESC')"> &#9660; </span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(m, i) in Members">
          <tr class="data" :key="`Member-${i}`">
            <td>{{ m.CustNo }}</td>
            <td>{{ m.Nm }}</td>
          </tr>
        </template>
      </tbody>
    </table>

    <h1 v-else> Ingen medlemmer funnet... {{ loading ? "ennå..." : '' }} </h1>

    <paginate v-if="hasMembers"
      class="ml-auto mt-5"
      container-class="pagination"
      page-class="span"
      prev-class="span"
      next-class="span"
      :page-count="pagination.last_page"
      :click-handler="onPageChange"
    ></paginate>
  </div>
</template>


<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import Paginate from 'vuejs-paginate'
  import debounce from "lodash/debounce";

  export default {
    name: "FinnKslStatus",
    components: {
      Paginate
    },
    data: () => ({
      SearchColumn: "Nm",
      search: "",
      perPage: 50,
      OrderColumn: "CustNo",
      OrderDir: "ASC",
      loading: false,
      page: 1,
      pagination: {
        current_page: 1,
        last_page: 1,
        total: 0
      },
    }),
    created () {
      if (!this.hasMembers) {
        this.getData()
      }
    },
    watch: {
      getMemberOptions (n,o) {
        const b1 = o.per_page != n.per_page //c
        const b2 = o.order != n.order //w
        const b3 = o.orderDir != n.orderDir //w
        const b4 = o.page != n.page //w
        const b5 = o.s_v != n.s_v //w
        if (b1 || b2 || b3 || b4 || b5) {
          this.getData()
        }
      }
    },
    computed: {
      ...mapState(['Members']),
      hasMembers() {
        return this.Members.length > 0
      },
      getMemberOptions() {
        return {
          per_page: this.perPage,
          order: this.OrderColumn,
          orderDir: this.OrderDir,
          page: this.page,
          s_c: this.SearchColumn,
          s_v: this.search
        }
      },
      SearchPlaceholder () {
        let p = ''
        switch (this.SearchColumn) {
          case 'Nm':
              p = "Søk etter navn"
            break;
          case 'CustNo':
              p = "Søk etter medlemsnummer"
            break;
          case 'Gr2':
              p = "Søk etter [0, 1, 2]"
            break;
          default:
              p = "Søk etter"
            break;
        }
        return p
      }
    },
    methods: {
      ...mapActions(['getMembers']),
      setOrder(col, dir) {
        this.OrderColumn = col;
        this.OrderDir = dir;
      },
      onPageChange(page) {
        this.page = page
      },
      getData: debounce(function () {
        this.loading = true
        this.getMembers(this.getMemberOptions).then(data => {
          delete data.data
          this.pagination = data
          this.loading = false
        })
      }, 500)
    }
  }
</script>

<style lang="scss">

.btn {
  width: 100%;
  height: auto;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  text-align: center;
  &:hover {
    background: #fbba0061;
  }
  &:active {
    background: #fbba0021;
  }
}

#FinnKslStatus {
  input, select {
    width: 25%;
    padding: 5px 10px;
    border: 2px solid black;
    font-size: 15px;
    height: 100%;
    -webkit-appearance: none;
    border-radius: 0px;
  }
  select {
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
  table {
    margin-top: 20px;
    width: 100%;
    thead {
      tr {
        th {
          padding: 5px 5px;
          background-color: black;
          &>div {
            display: flex
          }
          div, span {
            color: white;
            font-size: 16px;
            text-transform: uppercase;
            text-align: start;
            margin-right: 5px;
          }
          .sorter {
            display: inline-flex;
            flex-direction: column;
            margin-left: 5px;
            span.sort-icon {
              color: white;
              font-size: 8px;
              cursor: pointer;
              &:hover {
                color: red;
              }
              &.active {
                color: red;
              }
            }
          }
        }
      }
    }
    tbody {
      box-shadow: 0px 2px black;
      tr.data {
        td {
          padding: 1px 5px;
        }
        td.date {
          width: 110px;
        }
      }
      tr.loading {
        text-align: center;
        height: 100px;
      }
    }
    tfoot {
      tr.LastRow {
        height: 30px;
        vertical-align: bottom;
        td {
          padding: 1px 5px;
        }
        td.number {
          text-align: right;
        }
      }
    }
  }
  .pagination {
    display: flex;
    margin-top: 50px;
    justify-content: flex-end;
    .span {
      list-style-type: none;
      margin-right: 3px;
      a {
        text-align: center;
        color: white;
        cursor: pointer;
        background-color: black;
        padding: 8px;
        margin: 1px;
        border: 1px solid black;
        &:hover {
          background-color: white;
          color: black;
        }
        &:focus {
          text-decoration: none;
          outline: none;
        }
      }
      &.active {
        a {
          background-color: white;
          color: black;
        }
      }
    }
  }
}
</style>

