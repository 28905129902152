import Vue from "vue";
import router from "./router";
import "./bootstrap";
import index from "./index.vue";
import store from "./store"
import "remixicon/fonts/remixicon.css";
new Vue({
    router,
    store,
    render: h => h(index)
}).$mount("#app");
