<template>
  <main class="login nyxzar">
    <div class="logo">
      <img src="/storage/static_assets/logo/Logo.png" alt="Logo">
    </div>
    <div class="form">
      <!-- owner form -->
      <div id="owner" class="card">
        <div  v-if="owner.sumbitting" class="overlay">
          <h1> Laster </h1>
          <img src="/svg/loading.svg" alt="loading">
        </div>
        
        <!-- Login Form -->
        <template v-if="!owner.forgot.status">
          <h3>Logg inn på min side</h3>
          <input  id="input-form-username" v-model="owner.username"  :class="owner.styles.username" type="text" placeholder="Brukernavn">
          <label for="input-form-username" class="d-block error-message required">Denne inndata er påkrevd</label>
          
          <div class="input-wrapper">
            <input id="input-form-password" @keyup.enter="submit('owner')" v-model="owner.password"  :class="owner.styles.password" type="password" placeholder="Passord">
            <!-- Alert to show when user has not changed his/her password yet for the first time -->
            <div v-if="owner.PasswordResetPrompt" class="alert-reset-password shadow-drop-2-center"> 
              <span @click="owner.PasswordResetPrompt = false" class="close-btn">x</span>
              <h3>Tving passordendring</h3>
              <p>
                Hei, <br>
                <br>
                For å beskytte dine medlemsdata, og være i samsvar med GDPR reglene,  ber vi alle brukere med konto på <a href="https://www.honning.no/">https://www.honning.no/</a> om å tilbakestille passordene sine. De nyopprettede passordene vil bli kodet før de lagres, og passordet vil etter dette kun være tilgjengelig for deg. Ditt gamle passord har nå opphørt å virke.
                <br><br>
                For å tilbakestille passordet ditt, vennligst trykk «glemt passord», og følg rutinen for å velge nytt passord.
                eller du kan endre passordet ditt <i @click.prevent="ChangePasswordForm(true, 'Bytt passord')" style="color: #0000e5;cursor: pointer;">her</i>
              </p>
              <p>
                <br>
              </p>
              <p>
                <b>Slik tilbakestiller du passordet på din brukerkonto:</b>
                <ol>
                  <li>Klikk på ”Glemt passord” </li>
                  <li>Angi kundenummeret ditt i inntastningsfeltet og klikk på knappen "Klikk her" </li>
                  <li>Skriv inn e-post eller mobiltelefonnummer og deretter klikk på send knappen for feltet </li>
                  <li>Basert på valget ditt, vil du motta en lenke via e-post eller en kode via sms. </li>
                  <li>Lenken i e-post vil føre deg til skjemaet for tilbakestilling av passord. Koden via sms må legges inn under knappen "Har du allerede mottatt tilbakestillingskoden </li>
                  <li>Skriv inn det nye passordet ditt i det første tekstfeltet og bekreft dette i det andre tekstfeltet. Klikk deretter på “Bytt Passord” </li>
                </ol>
              </p>
            </div>
          </div>

          <!-- error messages -->
          <template v-if="owner.errors && owner.status != 'ForcePasswordReset' ">
            <div class="alert alert-danger">
              {{ owner.status }}
            </div>
          </template>
          <label for="input-form-password" class="d-block error-message required">Denne inndata er påkrevd</label>

          <button @click="submit('owner')"> Logg inn </button>
          <button @click="ChangePasswordForm(true, 'Glemt passord')" class="btn-forgot"> Glemt passord </button>
        </template>

        <!-- Forgot Password Form -->
        <template v-else>
          <h3 style="margin-top: 200px;">{{ owner.forgot.title }}</h3>
          <div class="input-wrapper">
            <!-- Alert to show when user has not changed his/her password yet for the first time -->
            <div v-if="owner.PasswordResetPrompt" class="alert-reset-password shadow-drop-2-center"> 
              <span @click="owner.PasswordResetPrompt = false" class="close-btn">x</span>
              <h3>Tving passordendring</h3>
              <p>
                Hei, <br>
                <br>
                For å beskytte dine medlemsdata, og være i samsvar med GDPR reglene,  ber vi alle brukere med konto på <a href="https://www.honning.no/">https://www.honning.no/</a> om å tilbakestille passordene sine. De nyopprettede passordene vil bli kodet før de lagres, og passordet vil etter dette kun være tilgjengelig for deg. Ditt gamle passord har nå opphørt å virke.
                <br><br>
                For å tilbakestille passordet ditt, vennligst trykk «glemt passord», og følg rutinen for å velge nytt passord.
                eller du kan endre passordet ditt <i @click.prevent="ChangePasswordForm(true, 'Bytt passord')" style="color: #0000e5;cursor: pointer;">her</i>
              </p>
              <p>
                <br>
              </p>
              <p>
                <b>Slik tilbakestiller du passordet på din brukerkonto:</b>
                <ol>
                  <li>Klikk på ”Glemt passord” </li>
                  <li>Angi kundenummeret ditt i inntastningsfeltet og klikk på knappen "Klikk her" </li>
                  <li>Skriv inn e-post eller mobiltelefonnummer og deretter klikk på send knappen for feltet </li>
                  <li>Basert på valget ditt, vil du motta en lenke via e-post eller en kode via sms. </li>
                  <li>Lenken i e-post vil føre deg til skjemaet for tilbakestilling av passord. Koden via sms må legges inn under knappen "Har du allerede mottatt tilbakestillingskoden </li>
                  <li>Skriv inn det nye passordet ditt i det første tekstfeltet og bekreft dette i det andre tekstfeltet. Klikk deretter på “Bytt Passord” </li>
                </ol>
              </p>
            </div>
          </div>

          <div class="d-flex" v-if="!owner.forgot.checked">
            <input type="text" class="forgot-password forgot-password-input" :disabled="owner.forgot.checked" v-model="owner.forgot.CustNo" placeholder="Kundenummer">
            <button class="forgot-password-btn" v-if="!owner.forgot.checked" @click="CheckUserForgotPassword('owner')"> Klikk her </button>
          </div>

          <!-- if user exist -->
          <template v-if="owner.forgot.checked">
            <p v-if="!owner.forgot.sms && !owner.forgot.email">
              Skriv inn e-post eller telefon nummer knyttet til kontoen og få tilsend en tilbakestillings kode. Det kan ta noen minutter før du mottatt koden.
            </p>
            <p v-else-if="owner.forgot.email">
              Skriv inn e-post knyttet til kontoen og få tilsend en tilbakestillings kode. Det kan ta noen minutter før du mottatt koden.
            </p>
            <p v-else-if="owner.forgot.sms">
              Skriv inn telefon nummer knyttet til kontoen og få tilsend en tilbakestillings kode. Det kan ta noen minutter før du mottatt koden.
            </p>
            <!-- Verification Via Email -->
            <template v-if="owner.forgot.maskData.HasEmail && !owner.forgot.sms">
              <span style="display: block;margin-top: 10px;">
                {{ owner.forgot.maskData.email }}
              </span>
              <div class="d-flex">
                <input type="text" class="forgot-password forgot-password-input" v-model="owner.forgot.email" placeholder="E-post">
                <button class="forgot-password-btn" v-if="!owner.forgot.submited" @click="SendForgotPasswordRequest('owner','email')"> 
                  Send e-post
                </button>
              </div>
            </template>
            <!-- Verification Via Sms -->
            <template v-if="owner.forgot.maskData.HasMobile && !owner.forgot.email">
              <span style="display: block;margin-top: 10px;">
                {{ owner.forgot.maskData.mobile }}
              </span>
              <!-- Form for sending sms code to owner -->
              <!-- hide form if code has been sent -->
              <template v-if="!owner.forgot.SmsStatus.SmsCodeSentStatus">
                <div class="d-flex">
                  <input type="text" class="forgot-password forgot-password-input" v-model="owner.forgot.sms" placeholder="Mobilnummer">
                  <button class="forgot-password-btn" v-if="!owner.forgot.submited" @click="SendForgotPasswordRequest('owner','sms')"> Send SMS </button>
                </div>
                <button class="d-block" style="width: 95%;margin-top: 30px;" v-if="!owner.forgot.submited && !owner.forgot.sms" @click="AlreadyHaveCode()"> Har du allerede mottatt tilbakestillingskoden? </button>
              </template>
              <!-- handle success sending of code -->
              <template v-if="owner.forgot.SmsStatus.SmsCodeSentStatus">
                <div class="d-flex">
                  <!-- show a form that lets users enter the code -->
                  <input type="text" class="forgot-password forgot-password-input" v-model="owner.forgot.SmsCode" placeholder="6 sifferkode">
                  <!-- show a button to let users submit the code -->
                  <button class="forgot-password-btn" @click="CheckSMSCode()"> Sjekk SMS </button>
                </div>
              </template>
              <!-- handle unsuccessful sending of code -->
              <template v-if="owner.forgot.SmsStatus.message">
                <!-- show error message -->
                <div>
                  {{ owner.forgot.SmsStatus.message }}
                </div>
              </template>
            </template>
          </template>

          <span v-if="owner.forgot.message" class="alert" :class="owner.forgot.messageClass">
            {{ owner.forgot.message }}
          </span>
          <div class="d-flex">
            <button @click="owner.forgot.status = false" class="btn-forgot"> Tilbake </button>
            <button @click="ContactUs()" class="btn-forgot" > Kontakt Oss </button>
          </div>
        </template>

        <!-- Error -->
        <div v-if="owner.forgot.is_error" class="alert warning">
          <strong>Advarsel!</strong> {{ owner.forgot.status }}
        </div>
      </div>

      <!-- press form -->
      <div id="press" class="card" v-if="!owner.forgot.status">
        <div v-if="press.sumbitting" class="overlay">
          <h1> Laster </h1>
          <img src="/svg/loading.svg" alt="loading">
        </div>
        <h3>Logg inn presse</h3>

        <input  v-model="press.username"  :class="press.styles.username" type="text" placeholder="Brukernavn">
        <label for="input-form-username" class="d-block error-message required">Dette skjemaet er påkrevd</label>

        <input @keyup.enter="submit('press')" v-model="press.password"  :class="press.styles.password" type="password" placeholder="Passord">
        <label for="input-form-username" class="d-block error-message required">Dette skjemaet er påkrevd</label>
        
        <button @click="submit('press')"> Logg inn </button>
        <div v-if="press.errors" class="alert alert-danger">
          <strong>Advarsel!</strong> {{ press.status }},
          Forsikre deg om at du oppga riktig brukernavn og passordkombinasjon.
        </div>
      </div>
    </div>

    <!-- <a href="/" class="back-btn">
      <i class="ri-home-3-line ri-lg"></i>
    </a> -->
    <a href="/" class="back-btn">
      <img src="/storage/static_assets/logo/Bie.png" alt="HONNINGCENTRALEN">
    </a>
  </main>
</template>

<script>
export default {
  name: "honning-login",
  data: () => ({
    owner: {
      username: "",
      password: "",
      styles: {
        username: [],
        password: []
      },
      PasswordResetPrompt: false,
      errors: false,
      status: "",
      sumbitting: false,
      forgot: {
        is_error: false,
        status: false,
        title: "Glemt passord",
        email: "",
        sms: "",
        SmsCode: "",
        CustNo: "",
        submitting: false,
        message: "",
        messageClass: "",
        submited: false,
        checked: false,
        SmsStatus: {
          SmsCodeSentStatus: false, // false if code has not been sent to phone
          CodeCheckStatus: false, // false if code is not match
          message: false
        },
        maskData: {
          HasEmail: false,
          email: "",
          HasMobile: false,
          mobile: ""
        }
      }
    },
    press: {
      username: "",
      password: "",
      styles: {
        username: [],
        password: []
      },
      errors: false,
      status: "",
      sumbitting: false
    }
  }),
  computed: {
    csrf() {
      return document.head.querySelector('meta[name="csrf-token"]').content;
    }
  },
  methods: {
    submit(form) {
      this.resetClass(form);
      let v = this.validate(form);
      if (v[0]) {
        let data = {
          username: this[form].username,
          password: this[form].password,
          _token: this.csrf
        };
        this[form].sumbitting = true;
        this.axios
          .post(`/${form}/login`, data)
          .then(res => {
            let { data, status } = res;
            if (data.error) {
              this[form].errors = true;
              this[form].status = data.error;
              if (data.error == "ForcePasswordReset") {
                this.owner.PasswordResetPrompt = true;
              }
            } else {
              if (data.page) {
                this.$router.push({ name: data.page });
              } else {
                this.$router.push({ name: form });
              }
            }
            this[form].sumbitting = false;
          })
          .catch(({ error, config, code, request, response }) => {
            // console.log(response, response.message === "CSRF token mismatch.");
            if (response.message === "CSRF token mismatch.") {
              this[form].errors = true;
              this[form].status = "Siden utløpt (lastes på nytt)";
              location.reload();
            }
            if (error) {
              this[form].errors = true;
              this[form].status = error;
            } else if (response.status === 500) {
              this[form].errors = true;
              this[form].status = "serverfeil";
            }
            this[form].sumbitting = false;
          });
      } else {
        this[form].styles[v[1]].push("error");
        for (const e of v[2]) {
          this[form].styles[v[1]].push(e);
        }
      }
    },
    validate(form) {
      if (this[form].username === "") {
        return [false, "username", ["required"]];
      } else if (this[form].password === "") {
        return [false, "password", ["required"]];
      } else if (this[form].csrf === "") {
        return [false, "csrf", ["required"]];
      } else {
        return [true];
      }
    },
    resetClass(form) {
      this[form].styles = {
        username: [],
        password: []
      };
    },
    SendForgotPasswordRequest(form, type) {
      this.owner.sumbitting = true;
      let data = false;
      data = {
        CustNo: this[form].forgot.CustNo,
        MailAd: this[form].forgot[type]
      };
      if (data) {
        this.axios
          .post("/ForgotPassword/RequestCode", data)
          .then(({ data, status }) => {
            this.owner.sumbitting = false;
            if (status == 200) {
              if (type == "email") {
                this.owner.forgot.submited = true;
                this.owner.forgot.message = "Vennligst sjekk din e-post";
                this.owner.forgot.messageClass = "info";
              } else if (type == "sms") {
                if (data.responses[0].accepted == 1) {
                  this.AlreadyHaveCode();
                } else {
                  this.owner.forgot.status = "error";
                  this.owner.forgot.message = "Vennligst sjekk SMS-en din";
                  this.owner.forgot.messageClass = "info";
                }
              } else {
                this.owner.status = false;
              }
            } else {
              this.owner.forgot.message =
                "Det oppsto en feil ved sending av e-posten. Prøv igjen";
              this.owner.forgot.messageClass = "warning";
            }
          })
          .catch(({ error, config, code, request, response }) => {
            this.owner.sumbitting = false;
            this.owner.forgot.errors = true;
            this.owner.forgot.status = response.data.message;
          });
      }
    },
    ChangePasswordForm(status, title) {
      this.owner.forgot.status = status;
      this.owner.forgot.title = title;
    },
    closeAlertResetPassword() {
      this.owner.errors = false;
      this.owner.status = "";
    },
    CheckUserForgotPassword(form) {
      if (this[form].forgot.CustNo) {
        this.owner.sumbitting = true;
        let data = false;
        let CN = this[form].forgot.CustNo.replace(/\D/g, "");
        data = {
          CustNo: CN
        };
        if (data) {
          this.axios
            .post("/ForgotPassword/Check", data)
            .then(({ data, status }) => {
              this.owner.sumbitting = false;
              if (data.status) {
                this.owner.forgot.checked = data.status;
                this.owner.forgot.submited = false;
                this.owner.forgot.message = false;
                this.owner.status = data.message;
                this.owner.forgot.maskData.HasEmail = data.hasEmail;
                this.owner.forgot.maskData.HasMobile = data.hasMobile;
                this.owner.forgot.maskData.email = data.mailAd;
                this.owner.forgot.maskData.mobile = data.MobPh;
              } else {
                this.owner.forgot.message = `Konto med kundenummer "${this[form].forgot.CustNo}" ikke funnet`;
                this.owner.forgot.messageClass = "warning";
              }
            })
            .catch(({ error, config, code, request, response }) => {
              this.owner.sumbitting = false;
              this.owner.forgot.errors = true;
              this.owner.forgot.status = response.data.message;
              this.owner.forgot.message = `Det oppstod et problem på serveren. Prøv igjen senere, problemet er allerede rapportert til administratorene`;
              this.owner.forgot.messageClass = "warning";
            });
        }
      } else {
        this.owner.forgot.message = `Skriv inn brukernavn / kontonummer i inntastingsfeltet`;
        this.owner.forgot.messageClass = "warning";
      }
    },
    CheckSMSCode() {
      // Verify Code
      this.axios
        .post("/ForgotPassword/SmsCodeVerify", {
          code: this.owner.forgot.SmsCode
        })
        .then(({ data, status }) => {
          // handle a matched code
          if (data.isMatched) {
            // show Password Reset Form
            this.$router.push(`/ForgotPassword/SmsCode/${data.code.code}`);
          } else {
            // handle a missmatch code
            // - show error message
            this.owner.forgot.message = "Kodefeil";
            this.owner.forgot.messageClass = "warning";
            // - let users have chance to resubmit
            this.owner.forgot.SmsStatus.SmsCodeSentStatus = false;
          }
        })
        .catch(() => {
          this.owner.forgot.message = `Det oppstod et problem på serveren. Prøv igjen senere, problemet er allerede rapportert til administratorene`;
          this.owner.forgot.messageClass = "warning";
        });
    },
    AlreadyHaveCode() {
      this.owner.forgot.sms = true;
      this.owner.forgot.email = false;
      this.owner.forgot.submited = true;
      this.owner.forgot.status = "ready";
      this.owner.forgot.SmsStatus.SmsCodeSentStatus = true;
    },
    ContactUs() {
      window.location.replace("/kontakt");
    }
  }
};
</script>


<style lang="scss" scoped>
@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0), translateY(-50%);
    transform: translateZ(0), translateY(-50%);
    -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.35);
  }
  100% {
    -webkit-transform: translateZ(50px), translateY(-50%);
    transform: translateZ(50px), translateY(-50%);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
.shadow-drop-2-center {
  -webkit-animation: shadow-drop-2-center 0.1s ease-in-out 5 alternate-reverse
    backwards;
  animation: shadow-drop-2-center 0.1s ease-in-out 5 alternate-reverse backwards;
}
body {
  overflow: hidden;
}
.d-block {
  display: block;
}

.error-message {
  color: red;
  display: none;
}

.login {
  height: 100vh;
  align-items: stretch;
  position: relative;
  .logo {
    align-items: center;
    img {
      margin: 0 auto;
      width: 100%;
    }
    p {
      text-align: center;
    }
  }
  .form {
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    .card {
      padding: 30px;
      position: relative;
      .overlay {
        position: absolute;
        background: #80808078;
        border-radius: 15px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        h1 {
          color: black;
          margin-right: 10px;
        }
        img {
          width: 30px;
        }
      }
      h3 {
        margin: 0;
        padding: 0;
      }
      input {
        width: 80%;
        font-size: 16px;
        margin-top: 10px;
        display: block;
        color: #191615;
        text-align: center;
        padding: 10px 0px 10px 20px;
        border: none;
        background: #fbba0000;
        text-align: left;
        cursor: text;
        outline: none;
        border: 2px black solid;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        transition: all 0.1s linear;
        &.error {
          border: 2px red solid;
        }
        &.required + label.required {
          display: block;
        }
      }
      .input-wrapper {
        position: relative;
        .alert-reset-password {
          position: absolute;
          right: 110%;
          top: 0px;
          background-color: white;
          transform: translate(0%, -30%);
          box-shadow: 0px 0px 95px -13px rgba(0, 0, 0, 0.27);
          width: 200%;
          padding: 25px;
          border-radius: 5px;
          z-index: 99;
          .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
          }
          ol {
            padding-left: 20px;
          }
        }
      }
    }
  }

  .back-btn {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 70px;
    height: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;

    i {
      transition: all 0.3s ease-in-out;
    }

    img {
      width: 100%;
    }

    &:hover,
    &:active {
      background-color: #00000017;
      color: #ffb703;
      i {
        color: #ffb703;
      }
    }
  }
}

//If Grid Not Supported
.login {
  display: block;
  .logo {
    display: block;
    width: 160px;
    min-width: 126px;
    img {
      margin-left: 30vw;
      margin-top: 42vh;
    }
  }

  .form {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 32%;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;

    .card {
      width: 100%;
      max-width: 386px;
      margin: auto;
    }
  }
}

.d-flex {
  display: flex;
  > button {
    border-left: none;
  }
}

.alert {
  padding: 10px;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 96%;
  margin-top: 10px;
  border: 3px solid;
  &.warning {
    background-color: #ff00007d;
    border: red;
  }
  &.info {
    background-color: #c3ecb17d;
    border: #5cff2f;
  }
}

button {
  margin-top: 10px;
  display: block;
  background: black;
  color: white;
  border: 2px black solid;
  font-size: 14px;
  padding: 10px 50px;
  cursor: pointer;
  &.btn-forgot {
    color: black;
    border: none;
    padding: 5px;
    background: transparent;
    text-decoration: underline;
    &:hover {
      color: white;
      background: black;
    }
    &:active {
      background: #aaaa;
      padding: 5px;
    }
  }
  &:active {
    color: white;
    background: black;
  }
  &:hover {
    color: black;
    background: white;
  }
}

.forgot-password-input {
  display: unset !important;
  width: 70% !important;
  padding: 10px 20px !important;
}

.forgot-password-btn {
  width: 30%;
  padding: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (display: grid) {
  .login {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    .logo {
      grid-area: 1 / 1 / 6 / 3;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 2fr 2fr 1fr 0fr 2fr 2fr;

      width: unset;
      min-width: unset;

      img {
        grid-area: 3 / 4 / 4 / 5;

        margin-left: unset;
        margin-top: unset;
      }
      p {
        grid-area: 4 / 3 / 5 / 6;

        text-align: unset;
      }
    }
    .form {
      grid-area: 1 / 3 / 6 / 5;
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      grid-template-rows: 1fr 2fr 2fr 1fr;

      position: unset;
      height: unset;
      top: unset;
      right: unset;
      width: unset;

      .card {
        max-width: unset;
        &#press {
          grid-area: 3 / 2 / 4 / 3;
        }
        &#owner {
          grid-area: 2 / 2 / 3 / 3;
        }
      }
    }
  }
}

@media screen and (max-width: 1039px) {
  //If Grid Not Supported
  .login {
    .logo {
      text-align: center;
      margin: auto;

      img {
        max-width: 100px;
        min-width: 100px;
        height: auto;

        margin: auto;
        padding: 36% 0;
      }
    }
    .form {
      position: relative;
      width: 100%;
      display: block;
      padding-top: 10%;

      .card {
        max-width: 700px;

        .input-wrapper {
          .alert-reset-password {
            right: 0;
          }
        }
      }
    }

    .back-btn {
      top: 10px;
      left: 10px;
    }
  }

  @supports (display: grid) {
    .login {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 2fr 2fr 2fr;
      .logo {
        grid-area: 1/1/3/5;
        margin: unset;

        img {
          margin: 0 auto;
          padding: inherit;
        }
      }
      .form {
        grid-area: 3/1/6/5;

        display: grid;
        position: inherit;
        width: inherit;
        padding-top: inherit;

        .card {
          margin: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .login {
    .form {
      box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.19),
        0 6px 6px rgba(0, 0, 0, 0.23);

      .card {
        max-width: 500px;
      }
    }
  }

  @supports (display: grid) {
    .login {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 2fr;
      .logo {
        grid-area: 1/1/3/7;
      }
      .form {
        grid-area: 3/1/7/7;
        box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.19),
          0 6px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }
}
</style>


