<template>
  <div id="Konto">
    <h1>Min Konto</h1>

    <p>Her ser du din kreditnotaer og fakturaer fra HC, samt dine innbetalinger og våre utbetalinger.</p>

    <select class="year" v-model="SelectedYear">
      <option :value="false">velg år</option>
      <option v-for="(v, i) in kontoyears" :value="i" :key="`KontoYear-${i}`">{{ i }}</option>
    </select>
    <template v-if="Konto.length !== 0">
      <table v-if="SelectedYear">
        <thead>
          <tr>
            <th>Dato</th>
            <th>Forfall</th>
            <th>Tekst</th>
            <th>Bilagsnr</th>
            <th>Inn konto</th>
            <th>Ut konto</th>
            <th>Se bilag</th>
          </tr>
        </thead>
        <tbody>
          <tr class="data">
              <td class="date">01.01.{{ SelectedYear }}</td>
              <td class="date"></td>
              <td>Overført saldo fra året før.</td>
              <td>
                <!-- BILAGSNR -->
              </td>
              <td>
                <!-- INN KONTO -->
                {{ Sum[SelectedYear].lastyear > 0 ? Math.abs(Sum[SelectedYear].lastyear) : '' }}
              </td>
              <td>
                <!-- UT KONTO -->
                {{ Sum[SelectedYear].lastyear < 0 ? Math.abs(Sum[SelectedYear].lastyear) : '' }}
              </td>
              <td>
                <!-- SE BILAG -->
              </td>
          </tr>
          <template v-for="(x,i) in Konto">
            <tr v-if="IsSelectedYear(x.VoDt)" class="data" :key="`data-${i}`">
                <td class="date">{{ formatDate(x.VoDt) }}</td>
                <td class="date">{{ formatDate(x.DueDt) }}</td>
                <td>{{ x.txt }}</td>
                <td>{{ x.VoNo }}</td>
                <td>{{ x.am < 0 ? Math.abs(x.am) : "" }}</td>
                <!-- <td>{{ x.am < 0 ? Math.round(x.am * 100) / 100 : "" }}</td> -->
                <td>{{ x.am > 0 ? Math.abs(x.am) : "" }}</td>
                <!-- <td>{{ x.am > 0 ? Math.round(x.am * 100) / 100 : "" }}</td> -->
                <td>
                  <a v-if="x.HasFile" :href="`/owner/api/konto/invoice/${x.VoNo}`" class="btn" title="Download Document" download>
                    <img src="/svg/pdf.svg" alt="pdf" height="25px">
                  </a>
                </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr v-if="SelectedYear" class="LastRow">
            <td></td>
            <td></td>
            <td>Sum</td>
            <td></td>
            <td>{{ Math.round(Sum[SelectedYear].in * 100) / 100 }}</td>
            <td>{{ Math.round(Sum[SelectedYear].out * 100) / 100 }}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <!-- <h3 class="salary">SALDO : {{ Sum.in - Sum.out }}</h3> -->
      <h3 v-if="SelectedYear" class="salary">SALDO : {{ Sum[SelectedYear].total }}</h3>
    </template>
    <h3 v-else class="loading">
      <span v-if="loading"> Loading...</span>
      <span v-else> No Konto Data </span>
    </h3>
  </div>
</template>


<script>
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    name: "Konto",
    data: () => ({
      SelectedYear: false,
      loading: false,
    }),
    created(){
      if(this.Konto.length == 0){
        this.loading = true
        this.getKonto().then(() => {
          this.loading = false
        });
      }
    },
    computed: {
      ...mapState(['Konto']),
      ...mapGetters(['kontoyears']),
      Sum() {
        let x = {}
        // check if has konto data
        if (this.konto) {return false}
        // loop each konto data grouped by year
        for (const y in this.kontoyears) {
          // loop each data inside the grouped data
          for (const k of this.kontoyears[y]) {
            let am = Math.abs(k.am) // turn negative value into positive
            // if x[2011] is not yet created then initialize it 
            if(!x.hasOwnProperty(y)){
              x[y] = {lastyear: 0,in: 0,out: 0,total: 0}
            }
            // if k.am is a negative number
            if( k.am < 0 ){
              x[y].in += am // add it inside the IN data 
              x[y].total += am // add it on total
            } else {
              x[y].out += am // add it inside the OUT data 
              x[y].total -= am // subtract it on total
            }
          }
          // end of grouped data loop

          // check if has data from last year..
          if (this.kontoyears.hasOwnProperty(y - 1)) {
            // check if the total is not equal to 0
            if(x[y].total !== 0) {
              let ly = y - 1 // save year as variable
              x[y].lastyear = x[ly].total // save lastyears total
              // check if lastyears total is less than 0
              if(x[ly].total < 0) {
                // we add it to out because a negative value is considered as an out
                x[y].out += Math.abs(x[y].lastyear)
              } else {
                // else add it to in
                x[y].in += x[y].lastyear
              }
              x[y].total = x[y].in - x[y].out
            }
          }
        }
        return x
      }
    },
    methods: {
      ...mapActions(['getKonto']),
      formatDate(date){
        let y = date.toString().substring(0, 4);
        let m = date.toString().substring(4, 6);
        let d = date.toString().substring(6, 8);
        return `${d}.${m}.${y}`
      },
      IsSelectedYear(date){
        return date.toString().substring(0, 4) == this.SelectedYear;
      },
      getYear(date){
        return date.toString().substring(0, 4)
      },
    },
  }
</script>

<style lang="scss" scoped>

.btn {
  width: 100%;
  height: auto;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  text-align: center;
  &:hover {
    background: #fbba0061;
  }
  &:active {
    background: #fbba0021;
  }
}

#Konto {
  .year {
    width: 25%;
    padding: 5px 10px;
    border: 2px solid black;
    margin-left: auto;
    font-size: 15px;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
  .salary {
    display: block;
    text-align: right
  }
  table {
    margin-top: 20px;
    width: 100%;
    thead {
      tr {
        th {
          padding: 5px 5px;
          text-align: start;
          color: white;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          background-color: black;
        }
      }
    }
    tbody {
      box-shadow: 0px 2px black;
      tr.data {
        td {
          padding: 1px 5px;
        }
        td.date {
          width: 110px;
        }
      }
      tr.loading {
        text-align: center;
        height: 100px;
      }
    }
    tfoot {
      tr.LastRow {
        height: 30px;
        vertical-align: bottom;
        td {
          padding: 1px 5px;
        }
        td.number {
          text-align: right;
        }
      }
    }
  }
}
</style>

