<template>
  <div id="innmelding">
    <h1>Innmelding av honning {{ year }}</h1>
    <p>Husk at innleveringsuke oppdateres <u>dagen etter</u> innmelding! Husk å klikke på "Registrer innmelding" etter at alle opplysninger er fylt ut. Om du senere må endre leveringsuke, ta kontakt med oss.</p>
    <ul class="form">
      <!-- Jeg innmelder -->
      <li class="flex">
        <span class="type">Jeg innmelder</span>
        <span class="colon"> : </span>
        <div>
          <select v-if=" this.enrollee.length !== 0 " v-model="JegInnmelder">
            <option v-for="(e, i) in enrollee" :value="e.Prodno" :key="`${e.Prodno}-${i}`">{{ e.Descr }}</option>
          </select>
          <select v-else v-model="JegInnmelder">
            <option :value="0">Loading Options</option>
          </select>
        </div>
      </li>
      <!-- Antall kilo -->
      <li class="flex">
        <span class="type">Antall kilo</span>
        <span class="colon"> : </span>
        <div>
          <input type="number" @keypress="isNumber($event)" v-model="kilo" :disabled="disableNumbers">
        </div>
      </li>
      <!-- Antall kuber denne sesongen -->
      <li class="flex">
        <span class="type">Antall kuber denne sesongen</span>
        <span class="colon"> : </span>
        <div>
          <input type="number" @keypress="isNumber($event)" v-model="SeasonCubes" :disabled="disableNumbers">
        </div>
      </li>
      <!-- Nærmeste leveringssted -->
      <li class="flex">
        <span class="type">Nærmeste leveringssted</span>
        <span class="colon"> : </span>
        <div>
          <select v-model="DeliveryPoint" :disabled="NoNumbers">
            <!-- Select delivery location -->
            <option value="0">Velg leveringssted</option>
            <option v-for="e in DeliveryLocations" :value="e.CustNo" :key="e.CustNo">{{ e.Nm }}</option>
          </select>
        </div>
      </li>
      <!-- Jeg ønsker å levere honning (Antatt uke) -->
      <li class="flex">
        <span class="type">Jeg ønsker å levere honning (Antatt uke)</span>
        <span class="colon"> : </span>
        <div>
          <select v-model="DeliveryDate" :disabled="DeliveryPoint === 0">
            <!-- Choose week -->
            <option value="0">Velg uke</option>
            <template v-for="e in DeliveryWeeks">
              <option v-if="WeekIsWithInRange(e.Val2)" :value="e.Val2" :key="e.Val2">Uke {{e.Val2}} ( maks {{ e.Val1 }}kg )</option>
            </template>
          </select>
        </div>
      </li>
    </ul>
    <button @click="reset"> Nullstill innmeldingsskjema </button>
    <button @click="register" :disabled="DeliveryDate == 0"> Registrer innmelding </button>
  </div>
</template>

// ✅ TODO clear fields on success of register
// TODO Show Success Message to user
// TODO if Error Show Error to user.

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: "innmelding",
  data: () => ({
    JegInnmelder: 0,
    kilo: 0,
    SeasonCubes: 0,
    DeliveryPoint: 0,
    DeliveryDate: 0,
  }),
  watch:{
    DeliveryPoint(n, o) {
      this.getDeliveryDateWeeks(n)
    },
    enrollee(n,o) {
      if( this.enrollee.length !== 0 ) {
        this.JegInnmelder = this.enrollee[0].Prodno
      }
    }
  },
  created()  {
    if( this.enrollee.length == 0 ){
      this.getEnrollee();
    }
    if (this.DeliveryLocations.length == 0) {
      this.getDeliveryLocations();
    }
    this.reset()
  },
  computed: {
    ...mapState(['profile','enrollee', 'DeliveryWeeks', 'DeliveryLocations']),
    year(){
      return new Date().getFullYear()
    },
    disableNumbers(){
      return this.DeliveryPoint != 0;
    },
    NoNumbers(){
      return this.kilo == 0 || this.SeasonCubes == 0
    },
  },
  methods: {
    ...mapActions(['getEnrollee','getDeliveryDateWeeks','getDeliveryLocations','RegisterInnmelding']),
    reset(){
      if( this.enrollee.length !== 0 ){
        this.JegInnmelder = this.enrollee[0].Prodno
      } 
      this.kilo = 0
      this.SeasonCubes = 0
      this.DeliveryPoint = 0
      this.DeliveryDate = 0
    },
    register(){
      this.RegisterInnmelding({
        // pk: 0, // Generated
        // Val3: this.year,
        // Cust: this.profile.AccountNumber,
        BgLnNo: this.DeliveryDate,
        ProdNo: this.JegInnmelder,
        EntNo: this.DeliveryPoint,
        Val1: this.kilo,
        Val2: this.SeasonCubes,
      }).then(({data}) => {
        this.JegInnmelder = 0;
        this.kilo = 0;
        this.SeasonCubes = 0;
        this.DeliveryPoint = 0;
        this.DeliveryDate = 0;
      })
    },
    isNumber(evt){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    WeekIsWithInRange(w){
      let week = moment().isoWeek();
      return w >= week
    }
  },
}
</script>

<style lang="scss" scoped>
#innmelding {
  ul {
    padding: 0;
    li {
      display: flex;
      margin: 10px 0;
      .type {
        width: 300px;
        align-self: center;
        font-weight: bold;
      }
      .colon {
        margin: 0px 10px;
        align-self: center;
      }
      div {
          align-self: center;
          flex: 1;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          input, select {
            width: 80%;
            padding: 5px 10px;
            border: 2px solid black;
            margin-left: auto;
            font-size: 15px;
            -webkit-appearance: none;
          }
          select {
            background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
            background-position: 100% 50%;
            background-repeat: no-repeat;
          }
      }
    }
  }
  button {
    margin-top: 10px;
    display: inline;
    background:black;
    color: white;
    border: 2px black solid;
    font-size: 14px;
    padding: 10px 50px;
    cursor: pointer;
    &:active {
      color: white;
      background: black;
    }
    &:hover {
      color: black;
      background: white;
    }
    &:disabled {
      background: gray;
    }
  }
}
@media only screen and (max-width: 655px) {
  #innmelding {
    ul {
      overflow: scroll;

      li {
        min-width: 500px;
      }
    }

    button {
      margin-bottom: 12px;
    }
  }
}

</style>
