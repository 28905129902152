<template>
  <main class="login">
    <div class="logo">
      <img src="/storage/static_assets/logo/Logo.png" alt="Logo" />
    </div>
    <div class="form">
      <div id="owner" class="card">
        <div v-if="sumbitting" class="overlay">
          <h1>Laster</h1>
          <img src="/svg/loading.svg" alt="loading" />
        </div>

        <template>
          <h3>Angi nytt passord</h3>
          <input
            v-if="email"
            id="input-form-username"
            disabled
            v-model="email"
            type="text"
            placeholder="Brukernavn"
          />
          <label
            for="input-form-username"
            class="d-block error-message required"
            >Dette inntastingsfeltet er påkrevd</label
          >

          <input
            id="input-form-password"
            v-model="password"
            type="password"
            placeholder="Passord"
          />
          <label
            for="input-form-password"
            class="d-block error-message required"
            >Dette inntastingsfeltet er påkrevd</label
          >

          <input
            id="input-form-confirm-password"
            v-model="confirmPassword"
            type="password"
            placeholder="Bekreft passord"
          />
          <label
            for="input-form-confirm-password"
            class="d-block error-message required"
            >Dette inntastingsfeltet er påkrevd</label
          >

          <button @click="ResetPassword('owner')">Bytt passord</button>
        </template>
        <div v-if="errors" class="alert alert-danger">
          <strong>Advarsel!</strong> {{ status }},
          <br />
          ikke bekymre deg problemet har blitt rapportert til administratorene
        </div>
      </div>
    </div>
    <a href="/" class="back-btn">
      <img src="/storage/static_assets/logo/Bie.png" alt="HONNINGCENTRALEN" />
    </a>
  </main>
</template>

<script>
export default {
  name: "ForgotPassword",
  data: () => ({
    email: "",
    password: "",
    confirmPassword: "",
    errors: false,
    status: "",
    sumbitting: false,
    submitted: false,
    code: false
  }),
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    } else {
      this.email = false;
      this.code = this.$route.params.code;
    }
  },
  computed: {
    csrf() {
      return document.head.querySelector('meta[name="csrf-token"]').content;
    }
  },
  methods: {
    ResetPassword(type) {
      if (this.email) {
        this.EmailResetPassword();
      } else {
        this.SmsResetPassword();
      }
    },
    EmailResetPassword() {
      let data = {
        MailAd: this.email,
        Gr: 1,
        password: this.password,
        password_confirmation: this.confirmPassword,
        token: this.$route.params.token,
        _token: this.csrf
      };
      this.sumbitting = true;
      this.axios
        .post("/ForgotPassword/reset", data)
        .then(({ data, status }) => {
          this.sumbitting = false;
          if (status == 200) {
            this.$router.push("/owner");
          } else {
          }
        })
        .catch(({ error, config, code, request, response }) => {
          this.sumbitting = false;
          (this.errors = true), (this.status = response.data.message);
        });
    },
    SmsResetPassword() {
      let data = {
        code: this.code,
        password: this.password,
        password_confirmation: this.confirmPassword,
        _token: this.csrf
      };
      this.sumbitting = true;
      this.axios
        .post("/ForgotPassword/Sms/reset", data)
        .then(({ data, status }) => {
          this.sumbitting = false;
          if (status == 200) {
            this.$router.push("/owner");
          } else {
          }
        })
        .catch(({ error, config, code, request, response }) => {
          this.sumbitting = false;
          (this.errors = true), (this.status = response.data.message);
        });
    }
  }
};
</script>


<style lang="scss" scoped>
@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0), translateY(-50%);
    transform: translateZ(0), translateY(-50%);
    -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.35);
  }
  100% {
    -webkit-transform: translateZ(50px), translateY(-50%);
    transform: translateZ(50px), translateY(-50%);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
.shadow-drop-2-center {
  -webkit-animation: shadow-drop-2-center 0.1s ease-in-out 5 alternate-reverse
    backwards;
  animation: shadow-drop-2-center 0.1s ease-in-out 5 alternate-reverse backwards;
}
body {
  overflow: hidden;
}
.d-block {
  display: block;
}

.error-message {
  color: red;
  display: none;
}

.login {
  height: 100vh;
  align-items: stretch;
  position: relative;
  .logo {
    align-items: center;
    img {
      margin: 0 auto;
      width: 100%;
    }
    p {
      text-align: center;
    }
  }
  .form {
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    .card {
      padding: 30px;
      position: relative;
      .overlay {
        position: absolute;
        background: #80808078;
        border-radius: 15px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        h1 {
          color: black;
          margin-right: 10px;
        }
        img {
          width: 30px;
        }
      }
      h3 {
        margin: 0;
        padding: 0;
      }
      input {
        width: 80%;
        font-size: 16px;
        margin-top: 10px;
        display: block;
        color: #191615;
        text-align: center;
        padding: 10px 0px 10px 20px;
        border: none;
        background: #fbba0000;
        text-align: left;
        cursor: text;
        outline: none;
        border: 2px black solid;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        transition: all 0.1s linear;
        &.error {
          border: 2px red solid;
        }
        &.required + label.required {
          display: block;
        }
      }
      .input-wrapper {
        position: relative;
        .alert-reset-password {
          position: absolute;
          right: 110%;
          top: 0px;
          background-color: white;
          transform: translate(0%, -30%);
          box-shadow: 0px 0px 95px -13px rgba(0, 0, 0, 0.27);
          width: 200%;
          padding: 25px;
          border-radius: 5px;
          z-index: 99;
          .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
          }
          ol {
            padding-left: 20px;
          }
        }
      }
    }
  }

  .back-btn {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 70px;
    height: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;

    i {
      transition: all 0.3s ease-in-out;
    }

    img {
      width: 100%;
    }

    &:hover,
    &:active {
      background-color: #00000017;
      color: #ffb703;
      i {
        color: #ffb703;
      }
    }
  }
}

//If Grid Not Supported
.login {
  display: block;
  .logo {
    display: block;
    width: 160px;
    min-width: 126px;
    img {
      margin-left: 30vw;
      margin-top: 42vh;
    }
  }

  .form {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 32%;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;

    .card {
      width: 100%;
      max-width: 386px;
      margin: auto;
    }
  }
}

.d-flex {
  display: flex;
  > button {
    border-left: none;
  }
}

.alert {
  padding: 10px;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 96%;
  margin-top: 10px;
  border: 3px solid;
  &.warning {
    background-color: #ff00007d;
    border: red;
  }
  &.info {
    background-color: #c3ecb17d;
    border: #5cff2f;
  }
}

button {
  margin-top: 10px;
  display: block;
  background: black;
  color: white;
  border: 2px black solid;
  font-size: 14px;
  padding: 10px 50px;
  cursor: pointer;
  &.btn-forgot {
    color: black;
    border: none;
    padding: 5px;
    background: transparent;
    text-decoration: underline;
    &:hover {
      color: white;
      background: black;
    }
    &:active {
      background: #aaaa;
      padding: 5px;
    }
  }
  &:active {
    color: white;
    background: black;
  }
  &:hover {
    color: black;
    background: white;
  }
}

.forgot-password-input {
  display: unset !important;
  width: 70% !important;
  padding: 10px 20px !important;
}

.forgot-password-btn {
  width: 30%;
  padding: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (display: grid) {
  .login {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    .logo {
      grid-area: 1 / 1 / 6 / 3;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 2fr 2fr 1fr 0fr 2fr 2fr;

      width: unset;
      min-width: unset;

      img {
        grid-area: 3 / 4 / 4 / 5;

        margin-left: unset;
        margin-top: unset;
      }
      p {
        grid-area: 4 / 3 / 5 / 6;

        text-align: unset;
      }
    }
    .form {
      grid-area: 1 / 3 / 6 / 5;
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      grid-template-rows: 1fr 2fr 2fr 1fr;

      position: unset;
      height: unset;
      top: unset;
      right: unset;
      width: unset;

      .card {
        max-width: unset;
        &#press {
          grid-area: 3 / 2 / 4 / 3;
        }
        &#owner {
          grid-area: 2 / 2 / 3 / 3;
        }
      }
    }
  }
}

@media screen and (max-width: 1039px) {
  //If Grid Not Supported
  .login {
    .logo {
      text-align: center;
      margin: auto;

      img {
        max-width: 100px;
        min-width: 100px;
        height: auto;

        margin: auto;
        padding: 36% 0;
      }
    }
    .form {
      position: relative;
      width: 100%;
      display: block;
      padding-top: 10%;

      .card {
        max-width: 700px;

        .input-wrapper {
          .alert-reset-password {
            right: 0;
          }
        }
      }
    }

    .back-btn {
      top: 10px;
      left: 10px;
    }
  }

  @supports (display: grid) {
    .login {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 2fr 2fr 2fr;
      .logo {
        grid-area: 1/1/3/5;
        margin: unset;

        img {
          margin: 0 auto;
          padding: inherit;
        }
      }
      .form {
        grid-area: 3/1/6/5;

        display: grid;
        position: inherit;
        width: inherit;
        padding-top: inherit;

        .card {
          margin: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .login {
    .form {
      box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.19),
        0 6px 6px rgba(0, 0, 0, 0.23);

      .card {
        max-width: 500px;
      }
    }
  }

  @supports (display: grid) {
    .login {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 2fr;
      .logo {
        grid-area: 1/1/3/7;
      }
      .form {
        grid-area: 3/1/7/7;
        box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.19),
          0 6px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }
}
</style>