<template>
  <div>
    <h1>Informasjon fra Honningcentralen</h1>
    <div class="container">
      <div v-for="x of Infos" class="wrapper">
        <div class="flex">
          <span class="circle normal"></span>
          <span class="pl-5 date">{{ x.date }}</span>
        </div>
        <div class="flex">
          <span class="circle long"></span>
          <span class="w-100 pl-5">
            <h2 class="title">{{ x.title }}</h2>
            <p class="paragraph" v-html="x.content"></p>
            <ul class="files">
              <li v-for="file of toJson(x.files)">
                <a :href="genPath(file.path)">{{ file.name }}</a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'info',
  created() {
    this.getInfos();
  },
  computed: {
    ...mapState(['Infos'])
  },
  methods: {
    ...mapActions(['getInfos']),
    genPath(p) {
      return `/storage/${p}`
    },
    toJson(t){
      return t ? JSON.parse(t) : [];
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  // padding: 10px;
  // border-bottom: 1px solid #fbba00;
  .flex {
    display: flex;
    margin: 10px 0px;
    .circle {
      width: 15px;
      border: 2px solid #fbba00;
      border-radius: 8px;
      justify-content: center;
      align-self: center;
      &.long {
        height: unset;
        align-self: stretch;
      }
      &.normal {
        height: 15px;
        
      }
    }
    .title {
      display: block;
      margin-top: 0;
    }
    .paragraph {
      display: block;
    }
    .files{
      display: block;
    }
  }
  .pl-5 {
    padding-left: 20px;
  }
  .w-100 {
    width: 100%;
  }
}
</style>
