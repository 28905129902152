<template>
  <div id="profile">
    <h1>Min profil</h1>
    <p>Velkommen! Vi har registrert følgende informasjon på deg:</p>
    <ul :class="Mode">
      <li v-for="(p, k) in template" :key="`${k}-list-profile`">
        <span class="type">{{ p[1] }}</span>
        <span class="colon">:</span>
        <template v-if="profile[p[0]] !== undefined">
          <template v-if="Mode === 'View'">
            <span v-if="p[2][1] === 'checkbox'" class="data">{{ profile[p[0]] == 1 ? 'Ja' : 'Nei' }}</span>
            <span v-else class="data">{{ profile[p[0]] }}</span>
          </template>
          <div v-else class="data">
            <input
              v-if="p[2][1] != 'checkbox'"
              :ref="`field-${p[0]}`"
              :disabled="!p[2][2]"
              :type="p[2][1]"
              v-model="profile[p[0]]"
            />
            <input
              v-else
              :disabled="!p[2][2]"
              type="checkbox"
              @change="KslChange($event, p[0])"
              :checked="profile[p[0]] == 1 ? true : false"
            />
            <span>{{ p[2][0] ? p[2][0] : "" }}</span>

            <span style="color: red;"> {{ validator(p) }} </span>
          </div>
        </template>
        <template v-else>
          <span class="data">Loading...</span>
        </template>
      </li>
    </ul>
    <button @click="Edit()" :disabled="sending || hasErrors">
      <span v-if="Mode === 'View'">Endre</span>
      <span v-else-if="hasErrors">Rett opp alle feil først</span>
      <span v-else-if="!sending">Lagre endringer</span>
      <span v-else>Lasting...</span>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Profile",
  data: () => ({
    Mode: "View",
    template: [],
    sending: false,
    hasError: false
  }),
  created() {
    if (!this.profile.id) {
      this.getProfile();
    }
    this.InitTemplate();
  },
  computed: {
    ...mapState(["profile"]),
    hasErrors() {
      if (this.profile.ActNo) {
        for (const temp of this.template) {
          let result = this.validator(temp);
          if (result) {
            return true
          }
        }
      }

      return false
    }
  },
  methods: {
    ...mapActions(["getProfile", "updateProfile"]),
    InitTemplate() {
      
      // [ 'Field name in Visma DB', 'Input Label', [ 'note', 'type', required ], [ ...validations() ] ]

      this.template.push(["Nm", "Firma / Navn", ["", "text", true]]);
      this.template.push(["Ad1", "Adresse1", ["", "text", true]]);
      this.template.push(["Ad2", "Adresse2", ["", "text", true]]);
      this.template.push(["PNo", "Postnr", ["", "text", true]]);
      this.template.push(["PArea", "Poststed", ["", "text", true]]);
      this.template.push(["Phone", "Telefon", ["", "text", true]]);
      this.template.push(["MailAd", "Email", ["", "text", true]]);
      this.template.push(["BGiro", "Kontonummer", ["", "text", true]]);
      this.template.push(["BsNo", "Foretaksnummer", ["", "text", true]]);
      this.template.push(["Inf5", "Produsentnummer", ["", "text", false]]);
      // this.template.push(['ProportionDeposits' ,'Andelsinnskudd' , ['', 'number', false]])
      this.template.push(["Inf2", "Kuber", ["", "number", true]]);
      this.template.push([
        "Inf", 
        "Frakt", 
        ["", "number", true], 
        [ 
          (v) => v.length > 4 ? 'Maks kun 4 sifre' : false,
          (v) => /^\d+$/.test(v) ? false : 'Kun antall er tillatt',
        ]
      ]);
      this.template.push(["Gr2", "KSL Godkjent", ["", "checkbox", false]]);
    },
    Edit() {
      if (this.Mode == "Edit") {
        this.sending = true;
        this.updateProfile(this.profile)
          .then((res) => {
            this.Mode = "View";
            this.sending = false;
          })
          .catch((e) => {
            this.Mode = "View";
            this.sending = false;
          });
      } else {
        this.Mode = "Edit";
        this.sending = false;
      }
    },
    KslChange(value, key) {
      this.profile[key] = value.target.checked ? 1 : 2;
    },
    validate_fields() {
      let valid = true;
      for (const fields of this.template) {
        let d = this.profile[p[0]];
        if (d == "" || d == null) {
          valid = false;
          break;
        }
      }
      return valid;
    },
    validator(template) {
      if(template[3]) {
        for (const v of template[3]) {
          let result = v(this.profile[template[0]])
          if (result) {
            return result
          }
        }
      }
    }
  },
};
</script>


<style lang="scss" scoped>
#profile {
  ul {
    padding: 0;
    li {
      display: flex;
      margin: 10px 0;
      .type {
        width: 140px;
      }
      .colon {
        margin: 0px 10px;
      }
      span.data {
        padding: 0px 10px;
      }
      div {
        flex: 1;
        width: 50%;
        input {
          width: 25%;
          padding: 5px 10px;
          border: 2px solid black;
          font-size: 15px;
          height: 100%;
          -webkit-appearance: none;
          border-radius: 0px;
        }
        span {
          font-size: small;
        }
      }
    }
  }
  button {
    margin-top: 10px;
    display: block;
    background: black;
    border: 2px black solid;
    font-size: 14px;
    padding: 10px 50px;
    cursor: pointer;
    span {
      color: white;
    }
    &:active {
      background: black;
      span {
        color: white;
      }
    }
    &:hover {
      background: white;
      span {
        color: black;
      }
    }
  }
}
</style>

