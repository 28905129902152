import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        profile: {},
        enrollee: [],
        DeliveryWeeks: [],
        DeliveryLocations: [],
        Shareholders: [],
        Innmeldinger: [],
        Leveranser: [],
        Konto: [],
        KontoCalculatedYears: [],
        Infos: [],
        Members: []
    },
    getters: {
        profile: state => state.profile,
        kontoyears: state => {
            var years = {};
            for (const k of state.Konto) {
                let y = k.VoDt.toString().substring(0, 4);
                if (!years.hasOwnProperty(y)) {
                    years[y] = [];
                }
                years[y].push(k)
            }
            return years;
        }
    },
    mutations: {
        InitProfile: (state, payload) => (state.profile = payload),
        InitEnrollee: (state, payload) => (state.enrollee = payload),
        InitDeliveryWeeks: (state, payload) => (state.DeliveryWeeks = payload),
        InitDeliveryLocations: (state, payload) =>
            (state.DeliveryLocations = payload),
        InitShareholder: (state, payload) => (state.Shareholders = payload),
        InitInnmeldinger: (state, payload) => (state.Innmeldinger = payload),
        InitLeveranser: (state, payload) => (state.Leveranser = payload),
        InitKonto: (state, payload) => (state.Konto = payload),
        InitKontoCalculatedYears: (state, payload) =>
            (state.KontoCalculatedYears = payload),
        InitInfo: (state, payload) => (state.Infos = payload),
        InitMembers: (state, payload) => (state.Members = payload)
    },
    actions: {
        getProfile({ commit }) {
            axios.get("/owner/api/profile").then(({ data }) => {
                commit("InitProfile", data);
            });
        },
        updateProfile({ commit }, payload) {
            return axios
                .post(`/owner/api/profile/update`, payload)
                .then(({ data }) => {
                    commit("InitProfile", data);
                    return data
                })
        },
        getEnrollee({ commit }) {
            return axios.get("/owner/api/enrollee").then(({ data }) => {
                commit("InitEnrollee", data);
            });
        },
        getDeliveryDateWeeks({ commit }, cust) {
            return axios
                .get(`/owner/api/delivery/weeks/${cust}`)
                .then(({ data }) => {
                    commit("InitDeliveryWeeks", data);
                });
        },
        getDeliveryLocations({ commit }) {
            return axios
                .get("/owner/api/delivery/Locations")
                .then(({ data }) => {
                    commit("InitDeliveryLocations", data);
                });
        },
        getShareholder({ commit }) {
            return axios.get("/owner/api/shareholders").then(({ data }) => {
                commit("InitShareholder", data);
            });
        },
        getInnmeldinger({ commit }) {
            return axios.get("/owner/api/innmeldinger").then(({ data }) => {
                commit("InitInnmeldinger", data);
            });
        },
        getLeveranser({ commit }) {
            return axios.get("/owner/api/leveranser").then(({ data }) => {
                commit("InitLeveranser", data);
            });
        },
        getKonto({ commit }) {
            return axios.get("/owner/api/konto").then(({ data }) => {
                commit("InitKonto", data);
            });
        },
        getInfos({ commit }) {
            return axios.get("/owner/api/info").then(({ data }) => {
                commit("InitInfo", data);
            });
        },
        RegisterInnmelding({ commit }, payload) {
            return axios.post(`/owner/api/innmeldinger/register`, payload);
        },
        getMembers({ commit }, payload) {
            return axios.get(`/owner/api/members`, { params: payload }).then(({ data }) => {
                commit("InitMembers", data.data);
                return data;
            });
        }
    }
});
